import React from "react"

const Message = ({ children }) => (
  <div className="tw-w-full tw-h-screen tw-flex tw-items-center tw-justify-center md:tw-items-start md:tw-mt-10">
    <div className="tw-w-full tw-max-w-lg tw-border tw-border-gray-400 tw-rounded tw-p-4 tw-mx-4 tw-shadow">
      {children}
    </div>
  </div>
)

export default Message
