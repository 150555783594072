import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faArrowLeft,
  faArrowRight,
  faList,
  faCalendarAlt,
  faCalendarDay,
  faTimes,
  faExternalLinkAlt,
  faRuler,
  faInfo,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faArrowLeft,
  faArrowRight,
  faList,
  faCalendarAlt,
  faCalendarDay,
  faTimes,
  faExternalLinkAlt,
  faRuler,
  faInfo
)
