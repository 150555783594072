import { useState, useEffect, useMemo } from "react"
import parseISO from "date-fns/parseISO"
import addDays from "date-fns/addDays"
import format from "date-fns/format"
import fullData from "./data"

const storageKey = "rr"
const storage = {
  get: () => {
    try {
      return JSON.parse(window.localStorage.getItem(storageKey))
    } catch (e) {
      return null
    }
  },
  set: (data) => window.localStorage.setItem(storageKey, JSON.stringify(data)),
  clear: () => localStorage.removeItem(storageKey),
  listen: (on) => {
    const listener = () => on(storage.get())
    window.addEventListener("storage", listener)
    return () => window.removeEventListener("storage", listener)
  },
}

const urlFormat = (d) => format(d, "yyyy-MM-dd")
const identity = (a) => a

const useEvents = (defaultData, mapPlans = identity) => {
  const [data, setData] = useState(storage.get() || defaultData)

  useEffect(() => {
    // Default data doesn't get saved to local storage
    if (data !== defaultData) {
      storage.set(data)
    }
  }, [data, defaultData])

  // Any local storage changes will set data
  useEffect(() => storage.listen(setData), [])

  const items = useMemo(() => {
    if (!data) return null
    const { plans, date, startEnd } = data
    const targetDate = parseISO(date)
    return mapPlans(
      plans
        .flatMap((k) => fullData[k])
        .map((e, i, { length: l }) => ({
          ...e,
          date: urlFormat(
            addDays(targetDate, i - (startEnd === "end" ? l - 1 : 0))
          ),
        }))
    )
  }, [data, mapPlans])

  const findItem = useMemo(() => {
    if (!items) return null
    const indicesByDate = items.reduce((acc, e, index) => {
      acc[e.date] = index
      return acc
    }, {})
    const findIndex = (date) =>
      indicesByDate[typeof date === "string" ? date : urlFormat(date)]
    const findItem = (date) => items[findIndex(date)]
    return { index: findIndex, item: findItem }
  }, [items])

  return {
    items,
    findItem,
    set: (d) => {
      setData(d)
      storage.set(d)
    },
    clear: () => {
      setData(null)
      storage.clear()
    },
  }
}

export default useEvents
