const RACES = [
  { id: "400m" },
  { id: "600m" },
  { id: "800m" },
  { id: "1000m" },
  { id: "1500m" },
  { id: "1600m" },
  { id: "Mile" },
  { id: "2000m" },
  { id: "1.5 Miles" },
  { id: "3000m" },
  { id: "3200m" },
  { id: "2 Miles" },
  { id: "4000m" },
  { id: "3 Miles" },
  { id: "5000m" },
  { id: "6000m" },
  { id: "4 Miles" },
  { id: "8000m" },
  { id: "5 Miles" },
  { id: "10km" },
  { id: "12km" },
  { id: "15km" },
  { id: "10 Miles" },
  { id: "20km" },
  { id: "1/2 Marathon" },
  { id: "15 Miles" },
  { id: "25km" },
  { id: "30km" },
  { id: "20 Miles" },
  { id: "Marathon" },
  { id: "50km" },
  { id: "50 Miles" },
  { id: "100km" },
  { id: "Relay" },
  { id: "Stage" },
]

const NON_RACES = [
  { id: "REBUILD", label: "Rebuild" },
  { id: "BASE", label: "Base" },
  { id: "HILL", label: "Hill" },
  { id: "STAMINA", label: "Stamina" },
  { id: "SPEED", label: "Speed" },
  { id: "HSSUMMER", label: "High School Summer" },
  { id: "HSXC", label: "High School Cross Country" },
  { id: "HSWINTER", label: "High School Winter" },
  { id: "XTRAINRECOVERY", label: "Cross-Train Recovery" },
  { id: "MARATHONRECOVERY", label: "Marathon Recovery" },
  { id: "RECOVERYSHORT", label: "Short Recovery" },
  { id: "RECOVERYMEDIUM", label: "Medium Recovery" },
  { id: "RECOVERYLONG", label: "Long Recovery" },
]

const slugName = (p = "") =>
  p.toString().replace(/\s/g, "").replace("1.5", "1point5").toLowerCase()

const slugNames = (arr) => arr.map((p) => ({ ...p, slug: slugName(p.id) }))

const races = slugNames(RACES)
const nonRaces = slugNames(NON_RACES)

module.exports = { slugName, races, nonRaces }
