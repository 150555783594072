import React, { useRef } from "react"
import { findDOMNode } from "react-dom"
import BigCalendar from "react-big-calendar/lib/Calendar"
import dateFnsLocalizer from "react-big-calendar/lib/localizers/date-fns"
import {
  firstVisibleDay,
  lastVisibleDay,
} from "react-big-calendar/lib/utils/dates"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import locale from "date-fns/locale/en-US"
import { useRect } from "@reach/rect"
import Toolbar from "./Toolbar"

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (d, options) => startOfWeek(d, { ...options, weekStartsOn: 1 }),
  getDay,
  locales: {
    "en-US": locale,
  },
})

const formats = {
  agendaDateFormat: "EEE M/d",
  agendaHeaderFormat: ({ start, end }, culture, local) =>
    local.format(start, "M/d", culture) +
    " \u2013 " +
    local.format(end, "M/d", culture),
}

export const monthVisibleDays = (d) => [
  firstVisibleDay(d, localizer),
  lastVisibleDay(d, localizer),
]

const AgendaEvent = ({ event, title, onSelectEvent }) => (
  <button onClick={() => onSelectEvent(event)}>{title}</button>
)

const getLineHeight = () => {
  let temp = document.createElement("div")
  temp.setAttribute("style", "margin:0px;padding:0px;")
  temp.classList.add("tw-text-base", "tw-leading-tight")
  temp.innerHTML = "test"
  temp = document.body.appendChild(temp)
  const value = temp.clientHeight
  document.body.removeChild(temp)
  return value
}

const MONTH = ".rbc-month-row"
const LINE_HEIGHT = getLineHeight()
const DAY_HEADER_HEIGHT = 24
const DAY_PADDING = 4

const Calendar = ({ onClear, view, ...props }) => {
  const ref = useRef()
  const contentRect = useRect(ref)
  const lines = contentRect?.height
    ? Math.floor(
        (contentRect?.height - DAY_HEADER_HEIGHT - DAY_PADDING) / LINE_HEIGHT
      )
    : 0

  return (
    <BigCalendar
      ref={(c) => (ref.current = findDOMNode(c)?.querySelector(MONTH))}
      view={view}
      localizer={localizer}
      formats={formats}
      messages={{
        agenda: <FontAwesomeIcon icon="list" />,
        month: <FontAwesomeIcon icon="calendar-alt" />,
        previous: <FontAwesomeIcon icon="arrow-left" />,
        next: <FontAwesomeIcon icon="arrow-right" />,
        today: <FontAwesomeIcon icon="calendar-day" />,
      }}
      views={["month", "agenda"]}
      style={{ height: "100vh" }}
      components={{
        event: (props) => (
          <div style={{ WebkitLineClamp: lines }}>{props.title}</div>
        ),
        toolbar: (toolbarProps) => (
          <Toolbar {...toolbarProps} onClear={onClear} />
        ),
        agenda: {
          event: (agendaProps) => (
            <AgendaEvent {...agendaProps} onSelectEvent={props.onSelectEvent} />
          ),
        },
      }}
      {...props}
    />
  )
}

export default Calendar
