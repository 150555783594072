import React from "react"
import { navigate } from "react-big-calendar/lib/utils/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button, { ButtonGroup } from "../Button"

const Toolbar = (props) => {
  const {
    localizer: { messages },
    label,
    views,
    view,
    onClear,
  } = props

  const onNavigate = (action) => props.onNavigate(action)
  const onView = (view) => props.onView(view)

  return (
    <div className="tw-flex tw-p-1 sm:tw-p-2 tw-justify-between tw-items-center tw-border-b tw-border-gray-400">
      <ButtonGroup responsive>
        {[
          {
            key: "today",
            children: messages.today,
            onClick: () => onNavigate(navigate.TODAY),
          },
          {
            key: "previous",
            children: messages.previous,
            onClick: () => onNavigate(navigate.PREVIOUS),
          },
          {
            key: "next",
            children: messages.next,
            onClick: () => onNavigate(navigate.NEXT),
          },
        ]}
      </ButtonGroup>

      <span className="tw-flex tw-items-center">
        <span className="tw-text-sm sm:tw-text-base">{label}</span>
        <Button
          className="tw-ml-1"
          rounded
          responsive
          color="red"
          size="small"
          onClick={(e) => window.confirm("Are you sure?") && onClear()}
        >
          <FontAwesomeIcon icon="times" />
        </Button>
      </span>

      {views.length > 1 && (
        <ButtonGroup responsive>
          {views.map((name, index, list) => ({
            key: name,
            active: view === name,
            onClick: () => onView(name),
            children: messages[name],
          }))}
        </ButtonGroup>
      )}
    </div>
  )
}

export default Toolbar
