import React, { forwardRef } from "react"
import { DialogOverlay, DialogContent } from "@reach/dialog"

const Modal = forwardRef(({ children, ...props }, ref) => (
  <div>
    <DialogOverlay
      className="tw-fixed tw-bg-white-50 tw-inset-0 tw-flex tw-items-start tw-items-center tw-z-20 tw-justify-center tw-py-2"
      ref={ref}
      {...props}
    >
      <DialogContent
        aria-label="Modal text"
        className="tw-shadow tw-bg-white tw-border tw-border-gray-400 focus:tw-border-gray-500 focus:tw-outline-none focus:tw-shadow-outline tw-max-w-screen-md tw-max-h-full tw-overflow-scroll tw-mx-2 tw-p-4"
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  </div>
))

export default Modal
