import React, { useCallback, useMemo, useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import cx from "classnames"
import { useSwipeable } from "react-swipeable"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import startOfDay from "date-fns/startOfDay"
import endOfDay from "date-fns/endOfDay"
import addDays from "date-fns/addDays"
import addMonths from "date-fns/addMonths"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import RunningEvent from "./RunningEvent"
import Calendar, { monthVisibleDays } from "./Calendar"
import useKey from "./useKey"

const urlFormat = (d) => format(d, "yyyy-MM-dd")

const eventClasses = (types) => {
  const type = types.map((c) => c.replace(/\s/g, "-")).join(" ")
  if (type === "easy-run") return "tw-bg-green-400 tw-border-green-400"
  if (type.includes("rest")) return "tw-bg-gray-800 tw-border-gray-800"
  if (type.includes("off")) return "tw-bg-gray-600 tw-border-gray-600"
  if (type === "long-run") return "tw-bg-orange-400 tw-border-orange-400"
  if (type === "race") return "tw-bg-purple-400 tw-border-purple-400"
  if (type.includes("steady-state")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("speed")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("hill")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("fartlek")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("tempo")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("fitness")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("progression")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("interval")) return "tw-bg-red-400 tw-border-red-400"
  if (type.includes("fast-finish")) return "tw-bg-red-400 tw-border-red-400"
  return ""
}

const RunningCalendar = ({
  items: events,
  findItem,
  onClear,
  view,
  date,
  detailDate,
  agendaLength = 30,
}) => {
  const navigate = useNavigate()

  const urlDate = useMemo(() => urlFormat(date), [date])

  const [firstVisibleEvent] = useMemo(() => {
    const [firstVisibleDate, lastVisibleDate] =
      view === "agenda"
        ? [parseISO(urlDate), addDays(parseISO(urlDate), agendaLength)]
        : monthVisibleDays(urlDate)
    const visibleDays = Array.from(
      new Array(differenceInCalendarDays(lastVisibleDate, firstVisibleDate))
    ).map((_, i) => addDays(firstVisibleDate, i))
    return [
      visibleDays.find(findItem.item),
      [...visibleDays].reverse().find(findItem.item),
    ].map((d) => (d ? findItem.item(d) : undefined))
  }, [view, urlDate, agendaLength, findItem])

  const navigateWithDefaults = useCallback(
    ({ view: v = view, date: d = urlDate, detailDate: dd } = {}) =>
      navigate(`/${v}/${d}${dd ? `/${dd}` : ""}`),
    [navigate, view, urlDate]
  )

  const detail = useMemo(() => {
    const index = detailDate && findItem.index(detailDate)
    const hasIndex = (dir) =>
      index + dir >= 0 && index + dir <= events.length - 1
    const event = index >= 0 ? events[index] : undefined
    return {
      index,
      hasIndex,
      event,
    }
  }, [findItem, detailDate, events])

  // TODO: fix how nothing happens when selected or detail goes out of month bounds
  // TODO: selected state for agenda

  const [selected, setSelected] = useState(detail.event)
  const lastSelected = useRef(detail.event || firstVisibleEvent)

  // When changing screens in the calendar view, if there is a currently selected
  // event then set selected to the first event on the new screen. Otherwise don't
  // change selected but set the last selected ref
  useEffect(() => {
    setSelected((s) => {
      if (s) {
        return firstVisibleEvent
      } else {
        lastSelected.current = firstVisibleEvent
        return s
      }
    })
  }, [firstVisibleEvent])

  // Selected always follows the detail event if it is active
  useEffect(() => {
    if (detail.event) {
      setSelected(detail.event)
    }
  }, [detail.event])

  // Always track the last selected event unless selected is removed
  useEffect(() => {
    if (selected) {
      lastSelected.current = selected
    }
  }, [selected])

  const setPrevNextSelected = useCallback(
    (dir) => {
      const index = selected && findItem.index(selected.date)
      const hasIndex = index + dir >= 0 && index + dir <= events.length - 1
      if (hasIndex) {
        setSelected(events[index + dir])
      }
    },
    [selected, findItem, events]
  )

  const setPrevNextDetail = useCallback(
    (dir) =>
      detail.hasIndex(dir) &&
      navigateWithDefaults({ detailDate: events[detail.index + dir].date }),
    [navigateWithDefaults, detail, events]
  )

  const setPrevNextView = useCallback(
    (dir) => navigateWithDefaults({ date: urlFormat(addMonths(date, dir)) }),
    [navigateWithDefaults, date]
  )

  const swipeHandlers = useSwipeable({
    onSwipedRight: () =>
      detail.event ? setPrevNextDetail(-1) : setPrevNextView(-1),
    onSwipedLeft: () =>
      detail.event ? setPrevNextDetail(1) : setPrevNextView(1),
  })

  useKey(
    { key: "ArrowLeft", mod: "altKey" },
    useCallback(() => {
      if (detail.event) {
        setPrevNextDetail(-1)
      } else {
        setPrevNextView(-1)
      }
    }, [detail, setPrevNextDetail, setPrevNextView])
  )

  useKey(
    { key: "ArrowLeft" },
    useCallback(() => {
      if (detail.event) {
        setPrevNextDetail(-1)
      } else if (selected) {
        setPrevNextSelected(-1)
      } else {
        setSelected(lastSelected.current)
      }
    }, [detail, setPrevNextDetail, setPrevNextSelected, selected])
  )

  useKey(
    { key: "ArrowDown" },
    useCallback(() => {
      if (!detail.event) {
        if (view === "agenda") {
          setPrevNextSelected(1)
        } else if (view === "month") {
          setPrevNextSelected(7)
        }
      }
    }, [view, detail, setPrevNextSelected])
  )

  useKey(
    { key: "ArrowUp" },
    useCallback(() => {
      if (!detail.event) {
        if (view === "agenda") {
          setPrevNextSelected(-1)
        } else if (view === "month") {
          setPrevNextSelected(-7)
        }
      }
    }, [view, detail, setPrevNextSelected])
  )

  useKey(
    { key: "ArrowRight", mod: "altKey" },
    useCallback(() => {
      if (detail.event) {
        setPrevNextDetail(1)
      } else {
        setPrevNextView(1)
      }
    }, [detail, setPrevNextDetail, setPrevNextView])
  )

  useKey(
    { key: "ArrowRight" },
    useCallback(() => {
      if (detail.event) {
        setPrevNextDetail(1)
      } else if (selected) {
        setPrevNextSelected(1)
      } else {
        setSelected(lastSelected.current)
      }
    }, [detail, setPrevNextDetail, setPrevNextSelected, selected])
  )

  useKey(
    { key: ["Enter", " "] },
    useCallback(
      (e) => {
        if (selected && !detail.event) {
          e.preventDefault()
          navigateWithDefaults({ detailDate: selected.date })
        } else if (!selected) {
          setSelected(lastSelected.current)
        }
      },
      [detail, navigateWithDefaults, selected]
    )
  )

  useKey(
    { key: "Escape" },
    useCallback(() => {
      if (selected && !detail.event) {
        setSelected(null)
      }
    }, [detail, selected])
  )

  return (
    <div {...(detail.event ? {} : swipeHandlers)}>
      <RunningEvent
        {...(detail.event ? swipeHandlers : {})}
        event={detail.event}
        onDismiss={() => navigateWithDefaults()}
        onPrev={detail.hasIndex(-1) && (() => setPrevNextDetail(-1))}
        onNext={detail.hasIndex(1) && (() => setPrevNextDetail(1))}
        eventClasses={eventClasses}
      />
      <Calendar
        view={view}
        date={date}
        events={events}
        selected={selected}
        length={agendaLength}
        onView={(v) => navigateWithDefaults({ view: v })}
        onNavigate={(d) => navigateWithDefaults({ date: urlFormat(d) })}
        onClear={onClear}
        onSelectEvent={(e) => navigateWithDefaults({ detailDate: e.date })}
        startAccessor={(e) => startOfDay(parseISO(e.date))}
        endAccessor={(e) => endOfDay(parseISO(e.date))}
        eventPropGetter={(event, start, end, selected) => {
          const classes = eventClasses(event.workout.type)
          return {
            className: cx(
              classes,
              selected && "rbc-selected",
              "tw-text-base",
              "tw-leading-tight",
              "tw-border",
              "tw-border-solid",
              classes.includes("bg-gray-") ? "tw-text-white" : "tw-text-black"
            ),
          }
        }}
      />
    </div>
  )
}

export default RunningCalendar
