const context = require.context(
  "../../../.data",
  true,
  // Edit this regex to filter different data files by goal, level, type, etc
  /-level2-comborunner-12marathon-5460\/parsed\.json$/
)

const data = {}

context.keys().forEach(function (key) {
  const planId = new URL(key, `http://t`).pathname.split("/").filter(Boolean)[0]

  const [id] = planId.split("-")

  if (data[id]) {
    throw new Error(`key ${id} from ${planId} already exists`)
  }

  data[id] = context(key)
})

export default data
