import React, { forwardRef } from "react"
import cx from "classnames"

const Button = forwardRef(
  (
    {
      className,
      as = "button",
      active,
      rounded = className && className.includes("-rounded-") ? false : true,
      responsive,
      disabled,
      color,
      size,
      ...props
    },
    ref
  ) =>
    React.createElement(as, {
      ref,
      className: cx(
        "focus:tw-outline-none focus:tw-shadow-outline tw-leading-none",
        disabled && "tw-cursor-not-allowed tw-opacity-50",
        rounded && "tw-rounded",
        size === "small"
          ? [
              "tw-py-1 tw-px-1 ",
              responsive ? "tw-text-xs sm:tw-text-sm" : "tw-text-sm",
            ]
          : [
              "tw-py-2 tw-px-2",
              responsive ? " tw-text-sm sm:tw-text-base" : "tw-text-base",
            ],
        color === "red"
          ? {
              "tw-bg-red-400 focus:tw-bg-red-700 tw-text-gray-800 focus:tw-text-gray-100": true,
              "hover:tw-bg-red-500": !disabled,
              "tw-bg-red-600": active,
              "tw-text-gray-900": active,
            }
          : color === "green"
          ? {
              "tw-bg-green-300 focus:tw-bg-green-600 tw-text-gray-800 focus:tw-text-gray-100": true,
              "hover:tw-bg-green-400": !disabled,
              "tw-bg-green-500": active,
              "tw-text-gray-900": active,
            }
          : {
              "tw-bg-gray-300 focus:tw-bg-gray-600 tw-text-gray-800 focus:tw-text-gray-100": true,
              "hover:tw-bg-gray-400": !disabled,
              "tw-bg-gray-500": active,
              "tw-text-gray-900": active,
            },
        className
      ),
      disabled,
      type: as === "a" ? undefined : "button",
      ...props,
    })
)
export const ButtonGroup = ({ children, className, responsive, ...props }) => (
  <span className={cx("tw-inline-flex", className)} {...props}>
    {children.map((props, index, list) => (
      <Button
        rounded={false}
        className={cx({
          "tw-rounded-l": index === 0,
          "tw-rounded-r": index === list.length - 1,
        })}
        responsive={responsive}
        {...props}
      />
    ))}
  </span>
)

export default Button
