import React, { createElement, useState, useEffect } from "react"
import cx from "classnames"
import dateFormat from "date-fns/format"
import parseISO from "date-fns/parseISO"
import startOfWeek from "date-fns/startOfWeek"
import Button from "./Button"
import fullData from "./data"
import Message from "./Message"
const plans = require("./data/plans")

const hasData = (p) => Object.prototype.hasOwnProperty.call(fullData, p.slug)
const racesWithData = plans.races.filter(hasData)
const plansWithData = plans.nonRaces.filter(hasData)

const Input = ({ as = "input", className, ...props }) =>
  createElement(as, {
    className: cx(
      className,
      "tw-bg-white tw-border tw-border-gray-400 hover:tw-border-gray-500 tw-px-4 tw-py-2 tw-pr-8 tw-rounded tw-shadow tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline tw-w-full"
    ),
    ...props,
  })

const CustomInput = ({ className, ...props }) => (
  <div className="tw-block tw-relative tw-w-full">
    <Input
      className={cx(className, "tw-block tw-appearance-none tw-w-full")}
      {...props}
    />
    <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700">
      <svg
        className="tw-fill-current tw-h-4 tw-w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)

const Select = (props) => <CustomInput as="select" {...props} />

const Label = ({ className, ...props }) => (
  <label
    className={cx(
      "tw-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-mb-2"
    )}
    {...props}
  />
)

const LabelInput = ({ label, id, children }) => (
  <>
    <Label htmlFor={id}>{label}</Label>
    {React.cloneElement(children, { id })}
  </>
)

const PlanOption = ({ id, label, slug }) => (
  <option value={slug}>
    {label || id} ({fullData[slug].length / 7} weeks)
  </option>
)

const PlanSelect = ({ value, ...props }) => (
  <Select
    {...props}
    value={value}
    className={cx({ "tw-text-gray-500": !value })}
  >
    <option value="">{value ? "(REMOVE)" : "Pick a plan"}</option>
    <optgroup label="Races">
      {racesWithData.map((p) => (
        <PlanOption key={p.id} {...p} />
      ))}
    </optgroup>
    <optgroup label="Other">
      {plansWithData.map((p) => (
        <PlanOption key={p.id} {...p} />
      ))}
    </optgroup>
  </Select>
)

const PickPlans = ({ onSubmit }) => {
  const [startEnd, setStartEnd] = useState("start")
  const initialDate = dateFormat(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
    "yyyy-MM-dd",
    new Date()
  )
  const [date, setDate] = useState(initialDate)
  const [plans, setPlans] = useState([])

  const isValid = startEnd && date && plans.length

  useEffect(() => {
    if (startEnd === "end" && date === initialDate) {
      setDate("")
    } else if (startEnd === "start" && !date) {
      setDate(initialDate)
    }
  }, [startEnd, date, initialDate])

  return (
    <Message>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (!isValid) return
          onSubmit({
            startEnd,
            date: dateFormat(parseISO(date), "yyyy-MM-dd", new Date()),
            plans,
          })
        }}
      >
        <div className="tw-flex tw-flex-wrap tw--mx-3 tw-mb-6">
          <div className="tw-w-full md:tw-w-1/2 tw-px-3 tw-mb-6 md:tw-mb-0">
            <LabelInput label="Start/End" id="startEnd">
              <Select
                value={startEnd}
                onChange={(e) => setStartEnd(e.target.value)}
              >
                <option value="start">Start</option>
                <option value="end">End</option>
              </Select>
            </LabelInput>
          </div>
          <div className="tw-w-full md:tw-w-1/2 tw-px-3">
            <LabelInput label={`Plan ${startEnd} date`} id="date">
              <CustomInput
                type="date"
                placeholder={initialDate}
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ height: 38 }}
              />
            </LabelInput>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap tw--mx-3 tw-mb-6">
          {[...plans, ""].map((plan, index) => (
            <div
              key={plan + index}
              className="tw-w-full md:tw-w-1/2 tw-px-3 tw-mb-0 md:tw-mb-0"
            >
              {index === 0 ? (
                <Label htmlFor="plan">Select Multiple Plans</Label>
              ) : (
                <Label>&nbsp;</Label>
              )}
              <PlanSelect
                id={plan ? undefined : "plan"}
                value={plan}
                onChange={(e) => {
                  const value = e.target.value
                  setPlans((s) =>
                    !plan
                      ? [...s, value]
                      : value
                      ? s.map((p, i) => (i === index ? value : p))
                      : s.filter((p, i) => i !== index)
                  )
                }}
              />
            </div>
          ))}
        </div>
        <div className="tw-flex tw-flex-wrap tw--mx-3 tw-mb-2 tw-justify-end">
          <div className="tw-w-full tw-px-3">
            <Button
              type="submit"
              disabled={!isValid}
              className="tw-w-full tw-py-2"
              color="green"
            >
              Set Plans
            </Button>
          </div>
        </div>
      </form>
    </Message>
  )
}

export default PickPlans
