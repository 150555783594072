import { useEffect } from "react"

const isPlainKeyEvent = (e, type = []) => {
  const types = Array.isArray(type) ? type : [type]
  return ["altKey", "metaKey", "shiftKey", "ctrlKey"].every((k) => {
    return e[k] === types.includes(k)
  })
}

const useKey = ({ key, mod }, fn) => {
  const keys = Array.isArray(key) ? key : [key]
  useEffect(() => {
    const listener = (e) => {
      if (keys.includes(e.key) && isPlainKeyEvent(e, mod)) {
        fn(e)
      }
    }
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [keys, mod, fn])
}

export default useKey
