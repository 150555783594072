import React from "react"
import { Link } from "react-router-dom"
import Message from "./Message"
import Button from "./Button"

const FourOhFour = () => {
  return (
    <Message>
      <h1 className="tw-text-xl tw-text-center">Not Found</h1>
      <p className="tw-mt-4 tw-text-center">
        <Link to="/" as={Button} color="red">
          Go home
        </Link>
      </p>
    </Message>
  )
}

export default FourOhFour
