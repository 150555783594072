import React from "react"
import { BrowserRouter, useParams, useRoutes } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import parseISO from "date-fns/parseISO"
import RunningCalendar from "./RunningCalendar"
import PickPlans from "./PickPlans"
import useEvents from "./useEvents"
import FourOhFour from "./404"

const defaultData = process.env.REACT_APP_PLANS
  ? {
      plans: process.env.REACT_APP_PLANS.split(","),
      date: process.env.REACT_APP_PLAN_START || process.env.REACT_APP_PLAN_END,
      startEnd: process.env.REACT_APP_PLAN_START ? "start" : "end",
    }
  : null

const calendarViews = { month: "month", agenda: "agenda" }
const validateDate = (d, def) => (d instanceof Date && !isNaN(d) ? d : def)

const CalendarRoute = ({ items, findItem, set, clear, view }) => {
  const isWide = useMediaQuery({ query: "(min-width: 767px)" })
  const { date, detailDate } = useParams()
  return items ? (
    <RunningCalendar
      view={view || (isWide ? calendarViews.month : calendarViews.agenda)}
      date={validateDate(parseISO(date), new Date())}
      detailDate={validateDate(parseISO(detailDate), null)}
      items={items}
      findItem={findItem}
      onClear={clear}
    />
  ) : (
    <PickPlans onSubmit={set} />
  )
}

const Routes = ({ elementProps }) =>
  useRoutes([
    { path: "/", element: <CalendarRoute {...elementProps} /> },
    ...Object.values(calendarViews).flatMap((view) => [
      {
        path: `/${view}`,
        element: <CalendarRoute view={view} {...elementProps} />,
      },
      {
        path: `/${view}/:date`,
        element: <CalendarRoute view={view} {...elementProps} />,
      },
      {
        path: `/${view}/:date/:detailDate`,
        element: <CalendarRoute view={view} {...elementProps} />,
      },
    ]),
    {
      path: "*",
      element: <FourOhFour />,
    },
  ])

const App = () => {
  const eventsData = useEvents(defaultData, (events) =>
    events
      .filter((e) => e.workout)
      .map((e) => ({
        ...e,
        allDay: true,
        title: `${e.workout.description} – ${e.workout.title}`,
      }))
  )

  return (
    <BrowserRouter>
      <Routes elementProps={eventsData} />
    </BrowserRouter>
  )
}

export default App
