import React, { useRef, forwardRef } from "react"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Linkify from "react-linkify"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import Modal from "./Modal"
import Button, { ButtonGroup } from "./Button"

const RunningEvent = forwardRef(
  ({ event, onDismiss, onPrev, onNext, eventClasses, ...props }, ref) => {
    const closeButtonRef = useRef()
    const { workout, date } = event || {}
    if (!workout || !date) return null

    return (
      <Modal
        {...props}
        ref={ref}
        onDismiss={onDismiss}
        isOpen
        initialFocusRef={closeButtonRef}
      >
        <div className="tw-flex tw-justify-between">
          <div>
            <h3>
              <FontAwesomeIcon icon="calendar-alt" fixedWidth />{" "}
              <strong>{format(parseISO(date), "EEEE MMMM d")}</strong>
            </h3>
            <h1>
              <FontAwesomeIcon icon="ruler" fixedWidth /> {workout.description}
            </h1>
            <h2>
              <FontAwesomeIcon icon="info" fixedWidth /> {workout.title}
            </h2>
            <div className="tw-mt-2">
              {workout.type.map((t) => (
                <span
                  key={t}
                  className={cx(
                    "tw-mr-1 tw-border-solid tw-border tw-rounded tw-p-1 tw-text-white",
                    eventClasses(workout.type)
                  )}
                >
                  {t}
                </span>
              ))}
            </div>
          </div>
          <div>
            <ButtonGroup>
              {[
                {
                  key: "prev",
                  children: <FontAwesomeIcon icon="arrow-left" />,
                  disabled: !onPrev,
                  onClick: onPrev || undefined,
                },
                {
                  key: "next",
                  children: <FontAwesomeIcon icon="arrow-right" />,
                  disabled: !onNext,
                  onClick: onNext || undefined,
                },
                {
                  ref: closeButtonRef,
                  key: "close",
                  children: <FontAwesomeIcon icon="times" />,
                  onClick: onDismiss,
                },
              ].filter(Boolean)}
            </ButtonGroup>
          </div>
        </div>

        <div className="tw-mt-4 tw-mb-2 tw-w-full tw-h-1 tw-border-t tw-border-gray-400" />
        <Linkify
          componentDecorator={(href, text, key) => (
            <Button
              as="a"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              key={key}
              size="small"
            >
              <FontAwesomeIcon icon="external-link-alt" />
            </Button>
          )}
        >
          <ul>
            {workout.purpose && (
              <li>
                <strong>Purpose:</strong> {workout.purpose}
              </li>
            )}
            {workout.prehab && (
              <li>
                <strong>Prehab:</strong> {workout.prehab}
              </li>
            )}
            {workout.notes && (
              <li>
                <strong>Notes:</strong> {workout.notes}
              </li>
            )}
            {workout.extra && (
              <li>
                <strong>Other:</strong> {workout.extra}
              </li>
            )}
          </ul>
        </Linkify>
      </Modal>
    )
  }
)

export default RunningEvent
